import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const AddressWrapper = styled.div`
  width: 100%;
  padding: 2rem 1.5rem 0;
  margin-bottom: 6rem;
  background: ${({theme}) => theme.colors.mainBlue};

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 45% 1fr;
  }
  ${({theme}) => theme.media.tabletXL} {
    padding: 5rem 3.5rem 0;
    margin-bottom: 9rem;
  }

  ${({theme}) => theme.media.laptop} {
    grid-template-columns: 1fr 1fr;
    width: 90%;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 6rem 7.5rem 0;
    margin-bottom: 13rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 6rem 9.5rem 0;
    margin-bottom: 13rem;
  }
`;

const ImgBox = styled.div`
  transform: translateY(3rem);

  ${({theme}) => theme.media.tablet} {
    transform: scale(1.1) translate(0.5rem, 0.5rem);
  }

  ${({theme}) => theme.media.laptopXL} {
    transform: scale(1) translate(1rem, 1rem);
  }
`;

const TextBox = styled.div`
  padding: 1rem 0;
  background: ${({theme}) => theme.colors.mainBlack};
  transform: translateY(3rem);

  ${({theme}) => theme.media.tabletXL} {
    transform: translateY(5rem);
  }

  ${({theme}) => theme.media.laptopXL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${({theme}) => theme.media.desktop} {
    transform: translateY(8rem);
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 3rem;
    width: 90%;
  }
`;

const TextBoxWrraper = styled.div`
  padding: 1.5rem;

  ${({theme}) => theme.media.tabletXL} {
    padding: 1.5rem 3rem;
  }

  ${({theme}) => theme.media.laptop} {
    margin: 0 1rem;
  }
`;

const H3 = styled.h3`
  font-size: calc(2.27273vw + 14.72727px);
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: 700;
  color: ${({theme}) => theme.colors.mainWhite};

  ${({theme}) => theme.media.laptop} {
    font-size: 1.9rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 2.5rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }
`;

const Text = styled.p`
margin: .3rem 0;
color: ${({theme}) => theme.colors.mainWhite};

${({theme}) => theme.media.desktop} {
  font-size: 1.4rem;
}
`;


const Address = () => {
  const data = useStaticQuery(graphql`
    query {
      headquartersImg: file(relativePath: { eq: "headquarters.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <AddressWrapper>
      <ImgBox>
        <Img fluid={data.headquartersImg.childImageSharp.fluid} alt="kontakt z siedzibą e-Pulpit24" />
      </ImgBox>
      <TextBox>
        <TextBoxWrraper data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">
          <H3>Siedziba w <span>Bydgoszczy</span></H3>
          <Text>ul. Kijowska 44</Text>
          <Text>85-703 Bydgoszcz</Text>
          <Text>(+48) 52 522 22 49</Text>
        </TextBoxWrraper>
        <TextBoxWrraper data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">
          <H3>Oddział w <span>Toruniu</span></H3>
          <Text>ul. Włocławska 167</Text>
          <Text>87-100 Toruń</Text>
          <Text>(+48) 52 522 22 49</Text>
        </TextBoxWrraper>
      </TextBox>
    </AddressWrapper>
  )
}

export default Address