import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import styled from 'styled-components'

const FormDiv = styled.div`
  width: 100%;
  padding: 1.5rem;

  ${({theme}) => theme.media.tablet} {
    padding: 3rem 1.5rem;
  }
`;

const HeaderForm = styled.div`
  width:100%;
`;

const H3 = styled.h3`
  font-size: 1.3rem;
  padding: 1rem;
  line-height: 1.3;
  color: ${({theme}) => theme.colors.mainBlue};

  ${({theme}) => theme.media.laptopXL} {
    font-size: 1.5rem;
  }
`;

const Form = styled.form`
  width: 100%;
  padding: 1rem;
`;

const FormWrapper = styled.div`
  width: 100%;
  font-size: .7rem;
  color: ${({ischeck}) => ischeck ? `${({theme}) => theme.colors.mainBlack}` : `#FF4A37`};
  font-weight: ${({ischeck}) => ischeck ? 600 : 400};
  font-family: ${({theme}) => theme.font.mainFont};
`;

const Input = styled.input`
  font-family: ${({theme}) => theme.font.mainFont};
  width: 100%;
  padding: .5rem;
  margin: .5rem 0;
  border: 3px solid ${({theme}) => theme.colors.mainBlue};

  :focus {
    outline: none;
  }
`;

const Textarea = styled.textarea`
  font-family: ${({theme}) => theme.font.mainFont};
  width: 100%;
  padding: .5rem;
  margin: .5rem 0;
  border: 3px solid ${({theme}) => theme.colors.mainBlue};
`;

const InputCheckBox = styled.input`
  margin-right: .4rem;
  font-size: .6rem;
`;

const Button = styled.button`
  font-family: ${({theme}) => theme.font.mainFont};
  display: inline-block;
  margin: .7rem 0;
  padding: .3rem 2rem;
  border: 3px solid ${({theme}) => theme.colors.mainBlue};
  letter-spacing: 1px;
  font-weight: 500;
  background: transparent;
  box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainYellow};
  cursor: pointer;
`;

const FinaleMsg = styled.p`
  color: ${({theme}) => theme.colors.mainBlack};
`;


const checkboxAcceptance = 'Wyrażam zgodę na przetwarzanie moich danych osobowych przez BluSoft Sp. z o.o. w celu i w zakresie niezbędnym do realizacji obsługi niniejszego zapytania.';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const API_PATH = 'https://maileepulpit.herokuapp.com/sendMail';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('imię i nazwisko jest wymagane'),
  email: Yup.string().email('adres email jest nieprawidłowy').required('adres e-mail jest wymagany'),
  phone: Yup.string().matches(phoneRegExp, 'numer telefonu jest nieprawidłowy'),
  textarea: Yup.string().required('wpisz wiadomość'),
  checkbox: Yup.bool()
  .test(
    'checkbox',
    'wymagana jest zgoda na przetwarzanie danych',
    value => value === true
  )
  .required()
});

const ContactForm = () => {

  const [sendMsg2, setSend2] = useState('')

  return (
      <FormDiv>
        <HeaderForm>
          <H3 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">Chcesz zamówić komputer w chmurze lub po prostu masz pytanie? Zostaw nam wiadomość.</H3>
        </HeaderForm>
        <Formik 
          initialValues={{ name: '', email: '', phone: '', textarea: '', checkbox: false }} 
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) =>{
            setSubmitting(true);
    
            const qs = require('qs');

            axios.post(`${API_PATH}`, qs.stringify(values, { parseArrays: false } ))
              .then(response => {
                if(response.status === 200) {
                  setSend2('ok');
                  setTimeout(() => {
                    setSend2('');
                  }, 3000)  
                }
              setSubmitting(false);
              values.checkbox = false
              resetForm()
            })
              .catch(error => {
              console.log(error)
              setSend2('notok');
              setTimeout(() => {
                setSend2('');
              }, 3000)
            })
            // setSubmitting(false);
            // resetForm();
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormWrapper>
                <Input
                  type="text" 
                  name="name" 
                  id="name" 
                  autoComplete="off"
                  placeholder="Imię*" 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {touched.name && errors.name ? <p>{errors.name}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper>
                <Input
                  type="email" 
                  name="email" 
                  id="email" 
                  autoComplete="off"
                  placeholder="E-mail*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {touched.email && errors.email ? <p>{errors.email}</p> : <p></p>}
                <Input
                  type="phone" 
                  name="phone" 
                  id="phone" 
                  autoComplete="off"
                  placeholder="Telefon"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                />
                {touched.phone && errors.phone ? <p>{errors.phone}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper>
                <Textarea
                  type="textarea" 
                  name="textarea" 
                  id="textarea" 
                  rows="7"
                  placeholder="Wiadomość*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.textarea}
                />
                {touched.textarea && errors.textarea ? <p>{errors.textarea}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper ischeck="ischeck">
                <InputCheckBox
                  type="checkbox"
                  name="checkbox" 
                  id="checkbox" 
                  onChange={handleChange}
                  value={values.checkbox}
                />{checkboxAcceptance}
                {touched.checkbox && errors.checkbox ? <p>{errors.checkbox}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper>
                <Button type="submit" disabled={isSubmitting}>Wyślij</Button>
              </FormWrapper>

              {sendMsg2 === 'ok' ? <FinaleMsg>Wiadomość została wysłana</FinaleMsg> : <p></p>}
              {sendMsg2 === 'notok' ? <FinaleMsg>Nie udało się wysłać wiadomości</FinaleMsg> : <p></p>}
            </Form>
          )}
        </Formik>

        </FormDiv>
  )
}

export default ContactForm;