import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'

const WrapperHeaderContact = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 10rem 0 0 0;
  }

  ${({theme}) => theme.media.laptop} {
    margin: 14rem 0 0 0;
    grid-template-columns: 1fr 1fr;
    background: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.laptopXL} {
    width: 95%;
    margin: 14rem 0 0 auto;
    height: 67vh;
  }

  ${({theme}) => theme.media.desktop} {
    width: 92%;
    margin: 19rem 0 0 auto;
    grid-template-columns: 55% 1fr;
    height: 59vh;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding-left: 5rem;
  }
`;

const TextBox = styled.div`
  width: 100%;
  padding: 6rem 1.5rem 4rem;
  background: ${({theme}) => theme.colors.mainBlue};

  ${({theme}) => theme.media.tablet} {
    padding: 0 1.5rem 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 0 3.5rem 2rem;
  }

  ${({theme}) => theme.media.desktop} {
    width: 70%;
    padding: 0 4.5rem 2rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 0 1rem 2rem 8.5rem;
  }
`;

const H1 = styled.h1`
  position: relative;
  color: ${({theme}) => theme.colors.mainBlack};
  padding: 1rem;
  font-size: calc(4.31818vw + 28.18182px);
  line-height: 1.3;

  ${({theme}) => theme.media.tablet} {
    transform: translateY(-35%);
  }

  ${({theme}) => theme.media.laptop} {
    transform: translateY(-45%);
    line-height: 1.1;
  }

  ${({theme}) => theme.media.laptopXL} {
    transform: translateY(-60%);
    line-height: 1.1;
  }

  ${({theme}) => theme.media.desktop} {
    transform: translateY(-80%);
    font-size: 5rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 1rem;
    width: 5rem;
    height: 5px;
    background: ${({theme}) => theme.colors.mainYellow};
  }
`;

const TextP = styled.p`
  color: ${({theme}) => theme.colors.mainWhite};
  font-size: calc(.68182vw + 13.81818px);
  line-height: 1.5;
  font-weight: 500;
  padding: 2rem 1rem;

  ${({theme}) => theme.media.tablet} {
    transform: translateY(-25%);
  }

  ${({theme}) => theme.media.laptop} {
    transform: translateY(-35%);
    padding: 2rem 4rem 2rem 1rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
    padding-right: 4rem;
  }
`;

const ConteinerImg = styled.div`
  display: none;

  ${({theme}) => theme.media.tablet} {
    display: block;
    height: 100%;
    background: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.tabletXL} {
    height: auto;
    background: ${({theme}) => theme.colors.mainWhite};
  }

  ${({theme}) => theme.media.laptop} {
    background: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.laptopXL} {
    height: 67vh;
  }

  ${({theme}) => theme.media.desktop} {
    height: 59vh;
  }
`;

const ImageBox = styled.div`


  ${({theme}) => theme.media.tablet} {
    transform: translateY(20%);
  }

  ${({theme}) => theme.media.tabletXL} {
    transform: translateY(-7%);
  }

  ${({theme}) => theme.media.laptop} {
    transform: translateY(-20%);
  }

  ${({theme}) => theme.media.laptopXL} {
    transform: translateY(-20%);
  }

  ${({theme}) => theme.media.desktop} {
    transform: translateY(-30%);

    img {
      height: 90% !important;
    }
  }

  ${({theme}) => theme.media.desktopXL} {
    transform: translateY(-26%);
    
    img {
      height: 80% !important;
    }
  }
`;


const HeaderContact = () => {
  const data = useStaticQuery(graphql`
    query {
      contactImg: file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <WrapperHeaderContact>
      <TextBox>
        <H1>Skontaktuj się z nami</H1>
        <TextP>Jesteśmy cyfrowymi myślicielami, którzy budują produkty dla Ciebie. Swoją siłę czerpiemy z samorozwoju oraz wymiany pomysłów. Poznaj nas i nasze produkty.</TextP>
      </TextBox>
      <ConteinerImg>
        <ImageBox>
          <Img fluid={data.contactImg.childImageSharp.fluid} alt="kontakt z e-Pulpit24"/>
        </ImageBox>
      </ConteinerImg>
    </WrapperHeaderContact>
  )
}

export default HeaderContact