import React from 'react'
import styled from 'styled-components'

// import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from '../components/contactComponents/contactForm'
import HeaderContact from '../components/contactComponents/headerContact'
import Address from '../components/contactComponents/address'

import ImageF from '../images/facebook-f-brands.svg'
import ImageL from '../images/linkedin-in-brands.svg'

const ContactWrapper = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 45% 1fr;
    padding-top: 3rem;
  }

  ${({theme}) => theme.media.tabletXL} {
    padding: 3rem;
  }

  ${({theme}) => theme.media.laptop} {
    max-width: 1200px;
    margin: 0 auto;
  }

  ${({theme}) => theme.media.desktop} {
    max-width: 1400px;
  }
`;

const ContactData = styled.div`
  width: 100%;
  padding: 3rem 3rem 1rem;

  ${({theme}) => theme.media.tablet} {
    padding: 3rem 2.5rem;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(3.18182vw + 21.81818px);

  ${({theme}) => theme.media.tablet} {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 3rem;
  }
`;

const SingleContact = styled.div`
  margin: 1rem 0;

  display: ${({isIcons}) => isIcons ? 'flex' : ''};

  svg {
    width: 1rem;
    color: ${({theme}) => theme.colors.mainBlue};
  }
`;

const Text = styled.p`
  color: ${({theme}) => theme.colors.mainBlack};
  margin: .5rem 0;

  ${({theme}) => theme.media.laptop} {
    font-size: 1.3rem;
  }
`;

const Button = styled.button`
  display: inline-block;
  display: flex;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.1rem;
  margin: 0 .5rem;
  color: ${({theme}) => theme.colors.mainBlack};
  border: ${({isFacebook}) => isFacebook ? `2px solid #46BAE8` : 'none'};
  padding: ${({isFacebook}) => isFacebook ? `.6rem 1rem;` : ''};
  box-shadow: ${({isFacebook}) => isFacebook ? `3px 3px 0 0 #E8DE2E` : ''};

  ${({theme}) => theme.media.laptop} {
    font-size: 1.3rem;
  }
`;


const ContactPage = () => {

  return (
    <>
      <SEO 
        title="Skontaktuj się z naszymi specjalistami od cloud computing."
        description="Zapraszamy do kontaktu. Pomożemy wybrać odpowiedni abonament. Nasi specjaliści od cloud computing odpowiedzą na każde pytanie o komputerze i pracy w chmurze."
      />

      <HeaderContact />
      <ContactWrapper>
        <ContactData>
          <H2>Porozmawiajmy</H2>
          <SingleContact>
            <Text>telefon:</Text>
            <Button as="a" href="tel:+48525222242">(+48) 52 522 22 49</Button>
          </SingleContact>
          <SingleContact>
            <Text>faks:</Text>
            <Button as="p">(+48) 52 522 22 49</Button>
          </SingleContact>
          <SingleContact>
            <Text>e-mail:</Text>
            <Button as="a" href="mailto:zgloszenia@e-pulpit24.pl">zgloszenia@e-pulpit24.pl</Button>
          </SingleContact>
          <SingleContact isIcons="isIcons">
            <Button isFacebook="isFacebook" as="a" href="https://www.facebook.com/ePulpit24" target="_blank">
              <ImageF />
            </Button>
            <Button isFacebook="isFacebook" as="a" href="https://www.linkedin.com/company/18892638/" target="_blank">
              <ImageL />
            </Button>
          </SingleContact>
        </ContactData>

        <ContactForm />

      </ContactWrapper>

      <Address/>
    </>
    
  )
}

export default ContactPage